/* lato_black */
@font-face {
  font-family: "Lato_black";
  /* font-style: normal;
    font-weight: 300; */
  src: url("./assets/fonts/admin-icons/fonts/Lato_Black.ttf"); /* IE9 Compat Modes */
  src: local(""),
    /* url('./assets/fonts/lato-v22-latin-300.eot?#iefix') format('embedded-opentype'), IE6-IE8 */
      /* url('./assets/fonts/lato-v22-latin-300.woff2') format('woff2'), Super Modern Browsers */
      /* url('./assets/fonts/lato-v22-latin-300.woff') format('woff'), Modern Browsers */
      url("./assets/fonts/admin-icons/fonts/Lato_Black.ttf") format("truetype"); /* Safari, Android, iOS */
  /* url('./assets/fonts/lato-v22-latin-300.svg#Lato') format('svg'); Legacy iOS */
}

/* Lato_BlackItalic */
@font-face {
  font-family: "Lato_BlackItalic";
  /* font-style: bold;
    font-weight: 900; */
  src: url("./assets/fonts/admin-icons/fonts/Lato_BlackItalic.ttf"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/admin-icons/fonts/Lato_BlackItalic.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
/* Lato_Light */

@font-face {
  font-family: "Lato_Light";
  /* font-style: bold;
    font-weight: 900; */
  src: url("./assets/fonts/admin-icons/fonts/Lato_Light.ttf"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/admin-icons/fonts/Lato_Light.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* Lato_LightItalic */

@font-face {
  font-family: "Lato_LightItalic";
  /* font-style: bold;
    font-weight: 900; */
  src: url("./assets/fonts/admin-icons/fonts/Lato_LightItalic.ttf"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/admin-icons/fonts/Lato_LightItalic.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

/* Lato_Thin */

@font-face {
  font-family: "Lato_Thin";
  /* font-style: bold;
    font-weight: 900; */
  src: url("./assets/fonts/admin-icons/fonts/Lato_Thin.ttf"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/admin-icons/fonts/Lato_Thin.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* Lato_ThinItalic */

@font-face {
  font-family: "Lato_ThinItalic";
  /* font-style: bold;
    font-weight: 900; */
  src: url("./assets/fonts/admin-icons/fonts/Lato_ThinItalic.ttf"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/admin-icons/fonts/Lato_ThinItalic.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

/* lato-regular - latin */
@font-face {
  font-family: "Lato_Regular";
  font-style: normal;
  /* font-weight: 400; */
  src: url("./assets/fonts/admin-icons/fonts/Lato_Regular.ttf"); /* IE9 Compat Modes */
  src: local(""),
    /* url('./assets/fonts/lato-v22-latin-regular.eot?#iefix') format('embedded-opentype'), IE6-IE8 */
      /* url('./assets/fonts/lato-v22-latin-regular.woff2') format('woff2'), Super Modern Browsers */
      /* url('./assets/fonts/lato-v22-latin-regular.woff') format('woff'), Modern Browsers */
      url("./assets/fonts/admin-icons/fonts/Lato_Regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
  /* url('./assets/fonts/lato-v22-latin-regular.svg#Lato') format('svg'); Legacy iOS */
}
/* lato-italic - latin */
@font-face {
  font-family: "Lato_Italic";
  font-style: italic;
  /* font-weight: 900; */
  src: url("./assets/fonts/admin-icons/fonts/Lato_Italic.ttf"); /* IE9 Compat Modes */
  src: local(""),
    /* url('./assets/fonts/lato-v22-latin-italic.eot?#iefix') format('embedded-opentype'), IE6-IE8 */
      /* url('./assets/fonts/lato-v22-latin-italic.woff2') format('woff2'), Super Modern Browsers */
      /* url('./assets/fonts/lato-v22-latin-italic.woff') format('woff'), Modern Browsers */
      url("./assets/fonts/admin-icons/fonts/Lato_Italic.ttf") format("truetype"); /* Safari, Android, iOS */
  /* url('./assets/fonts/lato-v22-latin-italic.svg#Lato') format('svg'); Legacy iOS */
}

/* lato_Bold */
@font-face {
  font-family: "Lato_Bold";
  font-style: bold;
  font-weight: 900;
  src: url("./assets/fonts/admin-icons/fonts/Lato_Bold.ttf"); /* IE9 Compat Modes */
  src: local(""),
    /* url('./assets/fonts/lato-v22-latin-900italic.eot?#iefix') format('embedded-opentype'), IE6-IE8 */
      /* url('./assets/fonts/lato-v22-latin-900italic.woff2') format('woff2'), Super Modern Browsers */
      /* url('./assets/fonts/lato-v22-latin-900italic.woff') format('woff'), Modern Browsers */
      url("./assets/fonts/admin-icons/fonts/Lato_Bold.ttf") format("truetype"); /* Safari, Android, iOS */
  /* url('./assets/fonts/lato-v22-latin-900italic.svg#Lato') format('svg'); Legacy iOS */
}

/* Lato_BoldItalic */

@font-face {
  font-family: "Lato_BoldItalic";
  font-style: bold;
  font-weight: 900;
  src: url("./assets/fonts/admin-icons/fonts/Lato_BoldItalic.ttf"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/admin-icons/fonts/Lato_BoldItalic.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

.font-regular-16 {
  font-family: "Lato_Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.font-regular-32 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
}
.font-regular-29 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
}
.font-regular-24 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
}

.font-regular-30 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
}
.font-regular-18 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

.font-regular-13 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
}
.font-regular-20 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.font-regular-21 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
}
.font-regular-14 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
