.header-item {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #004d92;
}

.userName {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #353a3d;
}

.notificationBadge {
  position: absolute;
  height: 20px;
  width: 20px;
  transform: translate(61%, -46%);
  border-radius: 50%;
  background-color: #eb5757;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-bottom: 6px;
  margin-bottom: 1px;
  color: #353a3d;
}

.notification-count {
  position: absolute;
  transform: translate(61%, -46%);
  font-family: "Lato";
  margin-left: 10px;
  margin-top: -1px;
  color: white;
  font-weight: 600;
  font-size: 10px;
}

.profile_pic1 {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

.dropdown-toggle {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  width: 30px !important;

  &:focus {
    box-shadow: none !important;
  }
}

.dropdown-divider {
  margin: 0 !important;
}

.dropdown-menu.show {
  border: none;
  width: 320px !important;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.user-icon {
  position: relative;
  border-radius: 50px;
}

.ring-photo {
  position: absolute;
  right: 0px;
  top: 1px
}

.profile-container {
  display: grid;
  justify-content: center;
  color: #222;

  .photo {
    position: relative;
    border-radius: 50px;
  }

  .ring {
    position: absolute;
  }



  .profile-name {
    color: #111827;
  }

  .profile-mail {
    color: #6B7280;
  }

  .profile-position {
    color: #374151;
  }

  .toggle-checkbox {
    margin: 0 5px;
    -webkit-appearance: none;
    appearance: none;
    width: 35px;
    height: 20px;
    background-color: #006ADA;
    border-radius: 25px;
    transition: background 0.6s;
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: #fff;
      display: block;
      position: absolute;
      top: 50%;
      left: 30%;
      transform: translate(-50%, -50%);
      transition: left 0.3s;
    }

    &:checked {
      background: #006ADA;
    }

    &:checked::after {
      left: 70%;
    }
  }
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #fff !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  padding: 0 !important;
  min-width: 0 !important;
  width: fit-content !important;
}


// ................................ DROPDOWN ............................

.dropdown-check-list {
  display: inline-block;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  // padding: 5px 50px 5px 10px;
  // border: 1px solid #ccc;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  // border-left: 2px solid black;
  // border-top: 2px solid black;
  // padding: 5px;
  right: 50px;
  top: 20%;
  // -moz-transform: rotate(-135deg);
  // -ms-transform: rotate(-135deg);
  // -o-transform: rotate(-135deg);
  // -webkit-transform: rotate(-135deg);
  // transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  position: absolute;
  background: #fff;
  top: 55px;
  right: 45px;

  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 2px;
  display: none;
  margin: 0;
  // border: 1px solid #ccc;
  // border-top: none;
}

.dropdown-check-list ul.items li {
  list-style: none;
  color: #374151;
  font-weight: 400;
}

.dropdown-check-list.visible .anchor {
  color: #0094ff;
}

.dropdown-check-list.visible .items {
  display: block;
}

.main-container {
  margin-top: -12px;
}

.web {
  color: #006ADA;
  text-decoration: none !important;
}

.heartCss {
  // color: #e00;
  animation: beat .60s infinite alternate;
  transform-origin: center;
  cursor: pointer;
}

@keyframes beat {
  to {
    transform: scale(1.2);
  }
}