$primary-color: #289f5b;
$primary-color-dark: #208e5a;
$light-gray: #e0e0e0;
$gold: #c89a32;
$orange: #f58d45;
$white: #fff;
$green: #0a8c5b;
$green-18: #189c5c;
$black: #000;
$black-50: #505050;
$black-1c: #1c2c25;
$grey: #929292;
$grey-65: #696464;
$black-4D: #4d4d4d;
$primary-color: #2a468d;
$secondary-color: #4a5c7b;
$green: #22db3b;
$white: #fff;
$danger: #ed1f24;
$black: #000;
$orange-f4: #f49d37;
$grey: #828282;
$grey-65: #6b6b6b;
$gray-dc: #dcdcdc;
$gray-fb: #fbfbfb;
$gray-67: #c7c7c7;
$gray-7e: #7e7e7e;
$gray-6c: #6c6c6c;
$gray-sideBar: #bdbdbd;
$gray-header: #f4f4f4;
$full-height: 100vh;
$background-white: #fbfbfb;
$border-grey: #c4c4c4;
$table-grey: #e0e0e0;
$common-white: #fafafa;
$grey-4B: #4b5563;
$blue-00: #006ada;
$white-D1: #d1fae5;
$green-06: #065f46;
$grey-6B: #6b7280;
$grey-B5: #b5b5b5;
$grey-D1: #d1d5db;
$grey-E5: #e5e7eb;
$blue-37: #374151;
$white-F9: #f9fafb;
$dark-blue-11: #111827;
$gray-e5: #e5e7eb;
$green-50: #50b89d;
$red-F8: #f87171;
$orange-ff: #fff7ed;
$orange-ea: #ea580c;
$grey-DA: #dadada;
$red-B9: #b91c1c;
$green-15: #15803d;
$red-DC: #dc2626;
$green-16: #166534;
$blue-DA: #0073DA;
$green-D1: #D1FAE5;
$orange-8E: #FFAA8E;
$orange-F9: #F97316;
$orange-dd5: #FFEDD5;
$blue-60: #60A5FA;
$red-E4: #EF4444;
/* Color */
$myColors: (
  "white": $white,
  "grey": $gray-sideBar,
  "grey-82": $grey,
  "green": $green,
  "primary-color": $primary-color,
);

@each $name,
$colors in $myColors {
  $index: index($myColors, $colors);

  .text-#{$name} {
    color: $colors;
  }

  .bg-#{$name} {
    background-color: $colors;
  }

  .bc-#{$name} {
    border-color: $colors;
  }
}

$full-height: 100vh;

.text-black {
  color: $black;
}

.text-black-50 {
  color: $black-50;
}

.text-black-30 {
  color: #303030;
}

.text-grey {
  color: $grey;
}

.text-black-4D {
  color: $black-4D;
}

.text-grey-65 {
  color: $grey-65;
}

.text-green-18 {
  color: $green-18;
}

.bg-green {
  background-color: $green-18;
}

.cursor-pointer {
  cursor: pointer;
}

.makeStyles-toolbar-151 {
  min-height: 0px !important;
}

.makeStyles-drawer-147 {
  width: 220px !important;
}

.makeStyles-content-8 {
  // background-color: #E5E5E5 !important;
  margin-left: 22px !important;
}

.ant-select-selector {
  border: none !important;
}

.ant-switch-checked {
  background-color: #0655a3 !important;
}

body {
  background-color: #f6f8fa !important;
}